import React from "react"
import Helmet from "react-helmet"
import LeftColumn from "../components/leftColumn"
import Cookie from "../components/cookie"
import SocialMobile from "../components/socialMobile"
import {titlesTranslation} from "../data/titles/titlesTranslations";

export default function About(data) {
    let language = data.pageContext.language;
    let t = data.pageContext.t;
    let titles = titlesTranslation[language];

    return (
        <div className="page">
            <Helmet>
                <title>{titles.about}</title>
                <meta name="description" content={titles.aboutDescription}/>
            </Helmet>

            <LeftColumn language={language} data={data}/>

            <div className="right-col">
                <h1>{t.about_header}</h1>

                <h2 className="slogan max-w-636" dangerouslySetInnerHTML={{__html: t.about_slogan}}/>

                <div className="story block-mt-88">
                    <div className="story__item">
                        <div className="story__description">
                            <h2>{t.about_story_header_1}</h2>
                            <div className="item-text fs-18">
                                {t.about_story_text_1}
                            </div>
                        </div>
                        <img src="/images/about-images/story.png" alt="" className="story__img-1"/>
                    </div>
                    <div className="story__item block-mt-88">
                        <img src="/images/about-images/who-we-are.png" alt="" className="story__img-2"/>
                        <div className="story__description">
                            <h2>{t.about_story_header_2}</h2>
                            <div className="item-text fs-18">
                                {t.about_story_text_2}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="what-we-do block-mt-88">
                    <h1 className="what-we-do__header" dangerouslySetInnerHTML={{__html: t.about_what_we_do_header}}/>
                    <div className="what-we-do__item">
                        <div className="what-we-do__item-header">
                            <h2>{t.about_what_we_do_subtitle_1}</h2>
                        </div>
                        <div className="what-we-do__description fs-18 max-w-636">
                            {t.about_what_we_do_text_1}
                        </div>
                    </div>
                    <div className="what-we-do__item">
                        <div className="what-we-do__item-header">
                            <h2>{t.about_what_we_do_subtitle_2}</h2>
                        </div>
                        <div className="what-we-do__description fs-18 max-w-636">
                            {t.about_what_we_do_text_2}
                        </div>
                    </div>
                    <div className="what-we-do__item">
                        <div className="what-we-do__item-header">
                            <h2>{t.about_what_we_do_subtitle_3}</h2>
                        </div>
                        <div className="what-we-do__description fs-18 max-w-636">
                            {t.about_what_we_do_text_3}
                        </div>
                    </div>
                </div>

                <SocialMobile/>
            </div>
            <Cookie language={language}/>
        </div>
    );
}